import { FeaturedItem, Button,Container } from '@components';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { autop } from '@wordpress/autop';

import { checkEnabled, checkValue } from '@components/OptionalFeature';
import { smBreakpoint, mdBreakpoint, lgBreakpoint, xlBreakpoint } from '@styles/styles.module.scss';
import styles from './Promotions.module.scss';

const Promotions = ({ data, componentIsAvailable }) => {
  const router = useRouter();
  const { locale } = router;
  const { Name, DisplayOptions } = data;

  //check Subject Enabled
  const SubjectAvailable = checkEnabled(DisplayOptions, 'Subject');
  const subject = checkValue(DisplayOptions, 'Subject', locale);

  //check BackgroundImage Enabled
  const backGroundImageAvailable = checkEnabled(DisplayOptions, 'BackgroundImage');
  // Backgound image
  const backGroundImage = DisplayOptions?.find((item) => item.DisplayOptionKey === 'BackgroundImage').OptionInformation;

  // check ButtonLinkURL Enabled
  const ButtonLinkURLAvailable = checkEnabled(DisplayOptions, 'ButtonLinkURL');
  // Button Link
  const ButtonLinkURL = DisplayOptions?.find((item) => item.DisplayOptionKey === 'ButtonLinkURL').OptionInformation;

  // check ButtonTarget Enabled
  const ButtonTargetAvailable = checkEnabled(DisplayOptions, 'ButtonTarget');
  // Button Target
  const ButtonTarget = DisplayOptions?.find((item) => item.DisplayOptionKey === 'ButtonTarget').OptionInformation;

  //check short description enabled
  const ShortDescriptionAvailable = checkEnabled(DisplayOptions, 'ShortDescription');

  // short description
  const shortDescription = checkValue(DisplayOptions, 'ShortDescription', locale);

  //check ButtonText enabled
  const ButtonTextAvailable = checkEnabled(DisplayOptions, 'ButtonText');
  // ButtonText
  const buttonText = checkValue(DisplayOptions, 'ButtonText', locale);

  //image alt
  const ImageAlt = checkValue(DisplayOptions, 'ImageAccessibilityInfo', locale);

  //check if it is Desktop or table for showing desciption
  let isTablet = useMediaQuery({
    minWidth: parseInt(smBreakpoint) + 1,
    maxWidth: parseInt(lgBreakpoint) - 1
  });
  let isDesktop = useMediaQuery({ minWidth: lgBreakpoint });

  return componentIsAvailable ? (
    <div className={styles[Name]}>
      <section className={styles.Section}>
        <Container className={styles.Container}>
          <FeaturedItem
            hasShadow={Name == 'PromotionLeft' || Name == 'PromotionRight' ? true : false}
            shadowType={Name == 'PromotionLeft' || Name == 'PromotionRight' ? 'BottomShadow' : null}
            title={SubjectAvailable ? subject : null}
            background={
              backGroundImageAvailable
                ? {
                    source: {
                      src: `/api/static/media/byFile${backGroundImage}`,
                      alt: `${ImageAlt}`
                    }
                  }
                : null
            }
            className={Name}
            href={ButtonLinkURLAvailable ? ButtonLinkURL : null}
          >
            {(isDesktop || isTablet) && ShortDescriptionAvailable && (
              <div
                dangerouslySetInnerHTML={{
                  __html: autop(shortDescription || '')
                }}
              />
            )}
            {buttonText && (
              <Button animated borderRadius href={ButtonLinkURLAvailable ? ButtonLinkURL : null} target={ButtonTargetAvailable ? ButtonTarget : null}>
                {ButtonTextAvailable ? buttonText : null}
              </Button>
            )}
          </FeaturedItem>
        </Container>
      </section>
    </div>
  ) : null;
};

export default Promotions;
