import { useContext } from 'react';
import classNames from 'classnames';

import PageContext from '@contexts/PageContext';
import { Layout , Container } from '@components';
import { PageSection } from '@containers';
import styles from './ErrorPage.module.scss';

const ErrorPage = () => {
  const data = useContext(PageContext);
  const { DisplayOptions, DarkMode } = data;

  return (
    <Layout className={styles.ErrorPage} DarkMode={DarkMode}>
      <Container className={classNames(styles.HomeBody)}>
        {DisplayOptions?.map((sectionData, index) => (
          <PageSection key={`homepage-section-${index}`} data={sectionData} DarkMode={DarkMode} />
        ))}
      </Container>
    </Layout>
  );
};

export default ErrorPage;
