import React, { useContext } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Link from 'next/link';

import PageContext from '@contexts/PageContext';
import { Container } from '@components';
import { checkEnabled } from '@components/OptionalFeature';
import { getLanguageId } from '@helpers/language';
import styles from './FeaturedOffers.module.scss';

const DynamicImage = dynamic(() => import('@components/DynamicImage'), {
  ssr: false,
});

const FeaturedOffers = ({ data,  componentIsAvailable, showTitle, componentTitle }) => {
  const pageData = useContext(PageContext);
  const router = useRouter();
  const { locale } = router;

  // ImageAccessibilityInfo
  const altEnabled = checkEnabled(data?.DisplayOptions, 'ImageAccessibilityInfo');

  return (
    <section className={styles.Section}>
      {componentIsAvailable && (
        <Container className={classNames(styles.Container)}>
          {showTitle && <h2 className={styles.Title}>{componentTitle}</h2>}
          {pageData.RelatedProducts && (
              <ul className={styles.List}>
                {pageData.RelatedProducts.map((item, index) => {
                  return (
                    <li key={index} className={styles.Tile}>
                      <Link href={`/products/${item.ProductCode}`} prefetch={false}>
                        <a>
                          <DynamicImage
                            imgSrc={`/api/static/media/byfile/products/${
                              item.ProductCode
                            }/PromotionalTile/1_N_N_N_I_450x325.JPG`}
                            size={'450x325'}
                            className={styles.Image}
                            alt={altEnabled ? item.Title : null}
                          />
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
          )}
        </Container>
      )}
    </section>
  );
};

export default FeaturedOffers;
