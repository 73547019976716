import { useContext } from 'react';
import classNames from 'classnames';

import PageContext from '@contexts/PageContext';
import { Layout, Container } from '@components';
import { PageSection } from '@containers';

import styles from './DefaultPage.module.scss';

const DefaultPage = () => {
  const data = useContext(PageContext);
  const { DisplayOptions, PageId, DarkMode } = data;

  const isFormElements = DisplayOptions?.find((x) => x.Name === 'FormElements');
  const isHeroElement = DisplayOptions?.find((x) => x.Name === 'Hero');
  const isBodyFullElement = DisplayOptions?.find((x) => x.Name === 'Body Full');
  const isBodyFullElementEnabled = isBodyFullElement?.DisplayOptions?.find((x) => x.DisplayOptionKey == 'DisplayComponent')?.Enabled;

  return (
    <Layout className={styles.DefaultPage} DarkMode={DarkMode}>
      {isHeroElement && (
        <PageSection
          data={isHeroElement}
          pageId={PageId}
          DarkMode={DarkMode}
        />
      )}
      <Container
        className={classNames({
          [styles.PostPageContainer]: data.PageId === 27,
          [styles.PostFullPageContainer]: data.PageId === 27 && isBodyFullElement && isBodyFullElementEnabled
        })}
      >
        {DisplayOptions?.filter((x) => x.Name !== 'Hero').map((sectionData, index) => (
          <PageSection
            key={`page-section-${index}`}
            data={sectionData}
            pageId={PageId}
            DarkMode={DarkMode}
            isFormElements={isFormElements}
          />
        ))}
      </Container>
    </Layout>
  );
};

export default DefaultPage;
