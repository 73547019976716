import { useContext, useEffect } from 'react';

import PageContext from '@contexts/PageContext';
import { Layout } from '@components';
import { getBackupImg } from '@helpers/dataExists';
import { ProductPageSection } from '@containers';
import styles from './CategoryGroup.module.scss';

const CategoryGroup = () => {
  const data = useContext(PageContext);
  const { DisplayOptions, DarkMode } = data;

  useEffect(async () => {
    await getBackupImg();
  });

  return (
    <Layout className={styles.DefaultPage} DarkMode={DarkMode}>
      {DisplayOptions?.map((sectionData, index) => (
        <ProductPageSection
          key={`page-section-${index}`}
          data={sectionData}
          productType={data.ProductType}
          DarkMode={DarkMode}
        />
      ))}
    </Layout>
  );
};

export default CategoryGroup;
