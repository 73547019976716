import { useContext } from 'react';
import useSWR from 'swr';

import API from '@utils/api';
import PageContext from '@contexts/PageContext';
import { useRouter } from 'next/router';
import styles from './Products.module.scss';

import { Container, ProductGroupTile } from '@components';

const Products = ({ data: sectionData, pageId, componentIsAvailable, showTitle, componentTitle }) => {
  const pageData = useContext(PageContext);
  const router = useRouter();
  const { locale } = router;

  let products;

  if (pageData.RelatedProducts) {
    products = pageData.RelatedProducts;
  } else {
    const {
      DisplayOptions: {
        ProductsArrayRelatedProducts: { OptionInformation: endpoint }
      }
    } = sectionData;

    const { data: productsData } = useSWR([
      '/api/relatedProducts', endpoint.toLowerCase()
    ], (url, endpoint) => API.post(url, { endpoint }));
    products = productsData;
  }

  return componentIsAvailable ? (
    <section className={pageData.PageId === 27 ? styles.Left : styles.Section}>
      <Container className={styles.Container}>
        {showTitle && <h2 className={styles.Title}>{componentTitle}</h2>}
        <ul className={styles.List}>
          {products.map((product) => (
            <li key={product.ProductId}>
              <ProductGroupTile
                data={product}
                sectionData={sectionData?.DisplayOptions}
                pageId={pageData.PageId}
              />
            </li>
          ))}
        </ul>
      </Container>
    </section>
  ) : null;
};

export default Products;
