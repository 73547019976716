import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { isFeatureEnabled, getFeatureDisplayValue, checkValue } from '@components/OptionalFeature';

const ProductHeroSection = dynamic(() => import('@containers/ProductHeroSection'));
const SubMenu = dynamic(() => import('@components/SubMenu'));
const ProductDescription = dynamic(() => import('@components/ProductDescription'));
const Video = dynamic(() => import('@components/Video'));
const Features = dynamic(() => import('@components/Features'));
const Models = dynamic(() => import('@containers/Models'));
const CompatibleImplements = dynamic(() => import('@containers/CompatibleImplements'));
const Downloads = dynamic(() => import('@components/Downloads'));
const RelatedProducts = dynamic(() => import('@containers/RelatedProducts'));
const Specs = dynamic(() => import('@components/Specs'));
const Accessories = dynamic(() => import('@containers/Accessories'));
const RelatedPosts = dynamic(() => import('@containers/RelatedPosts'));
const Promotions = dynamic(() => import('@components/Promotions'));
const Articles = dynamic(() => import('@containers/ProductArticles'));
const Summary = dynamic(() => import('@containers/Summary'));
const FeaturedOffers = dynamic(() => import('@containers/FeaturedOffers'));
const PromotionTiles = dynamic(() => import('@containers/PromotionTiles'));

const ProductPageSectionMap = {
  'Hero': ProductHeroSection,
  'Summary': Summary,
  'SubMenu': SubMenu,
  'Overview': ProductDescription,
  'Video': Video,
  'Features': Features,
  'Models': Models,
  'Specs': Specs,
  'Compatible Implements': CompatibleImplements,
  'Downloads': Downloads,
  'Related Products': RelatedProducts,
  'Accessories': Accessories,
  'Promotions': Promotions,
  'Related Articles': Articles,
  'Related Posts': RelatedPosts,
  'Featured Offers': FeaturedOffers,
  'PromotionTiles': PromotionTiles
};

const ProductPageSection = ({ data, productType, DarkMode }) => {
  const router = useRouter();
  const { locale } = router;
  const SectionName = data.Name;

  const Module = ProductPageSectionMap[SectionName];

  const componentIsAvailable = isFeatureEnabled(data?.DisplayOptions, SectionName);

  const showTitle = isFeatureEnabled(data?.DisplayOptions, SectionName, 'ComponentHeaderTitleText');

  const componentTitle = getFeatureDisplayValue(data?.DisplayOptions, SectionName, 'ComponentHeaderTitleText', locale);

  const anchorText = checkValue(data?.DisplayOptions, 'SubMenuAnchorText', locale) || SectionName;

  const props = {
    data,
    componentIsAvailable,
    showTitle,
    componentTitle,
    productType,
    DarkMode
  };

  return Module ? <Module id={anchorText} {...props} /> : null;
};

export default ProductPageSection;
