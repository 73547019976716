import { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { autop } from '@wordpress/autop';
import PageContext from '@contexts/PageContext';
import { Container, ProductTile, ComparePopup, Modal } from '@components';
import { compareProductSelector } from '@stores/compareProduct/selectors';
import { resetProductCompareCodeAction } from '@stores/compareProduct/actions';
import { Default, Mobile } from '@helpers/breakpoints';
import { checkEnabled, checkValue } from '../../components/OptionalFeature';
import styles from './CompatibleImplements.module.scss';

const DynamicImage = dynamic(() => import('@components/DynamicImage'), {
  ssr: false
});
const CompatibleImplements = ({ data, containerClass, listClass, id, DarkMode, componentIsAvailable, showTitle, componentTitle }) => {
  const dispatch = useDispatch();
  const pageData = useContext(PageContext);
  const router = useRouter();
  const { locale } = router;
  const checkedProducts = useSelector(compareProductSelector);

  const [current, setCurrent] = useState(0);
  const [isTwoColumnView, setIsTwoColumnView] = useState(false);

  //in mobile size -two column style modal opens
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});


  //Array of ready products to compare
  const compareProducts = checkedProducts
    .map((x) => {
      return pageData.CompatibleImplements.find((y) => y.ProductCode == x);
    })
    .filter((f) => {
      return f ? f : false;
    });

  //hasStudio
  const hasStudio = checkEnabled(data?.DisplayOptions, 'Studio');

  //Compare
  const CompareEnabled = checkEnabled(data?.DisplayOptions, 'DisplayCompare');

  //featureText
  const featureTextEnabled = checkEnabled(data?.DisplayOptions, 'FeatureText');

  //featureTitle
  const featureTitleEnabled = checkEnabled(data?.DisplayOptions, 'FeatureTitle');

  //The section title on the page
  const sectionTitle = checkValue(data?.DisplayOptions, 'ComponentHeaderTitleText', locale);

  // check ImageAccessibilityInfo Enabled
  const imageAltEnabled = checkEnabled(data?.DisplayOptions, 'ImageAccessibilityInfo');

  //check SellPriceAvailbe
  const sellPriceEnabled = checkEnabled(data?.DisplayOptions, 'SellPrice');

  //check sellPriceText Available
  const sellPriceTextAvailable = checkEnabled(data?.DisplayOptions, 'SellPriceText');

  //Get the value of the sell text
  const sellPriceText = checkValue(data?.DisplayOptions, 'SellPriceText', locale);

  //check SubTitle Enabled
  const subTitleAvailable = checkEnabled(data?.DisplayOptions, 'SubTitle');

  //check Title of each item Enabled
  const titleEachItemAvailable = checkEnabled(data?.DisplayOptions, 'Title');

  //check TwoColumnView
  const twoColumnView = checkEnabled(data?.DisplayOptions, 'TwoColumnView');

  //check LinkOut
  const linkOutEnabled = checkEnabled(data?.DisplayOptions, 'LinkOut');

  useEffect(() => {
    if (!checkedProducts.every((e) => pageData.CompatibleImplements.map((m) => m.ProductCode).includes(e))) {
      // dispatch(resetProductCompareCodeAction());
    }

    {
      twoColumnView ? setIsTwoColumnView(true) : setIsTwoColumnView(false);
    }
  }, []);

  useEffect(() => {
    thumbnailEventHandler(current);
  }, [current]);

  const thumbnailEventHandler = (inx) => {
    setCurrent(inx);
  };
  const openModel = (id) => {
    const selectedItem = pageData.CompatibleImplements.find((item) => item.Product.ProductCode == id);
    setShowModal(true);
    setModalData(selectedItem);
  };

  return (
    <section
      className={classNames(styles.Section, DarkMode ? styles.DarkMode : null)}
      id={id?.match(/compatible/i) ? 'CompatibleImplements' : id}
    >
      {(componentIsAvailable && pageData.CompatibleImplements.length > 0) && (
        <Container className={classNames(styles.Container, containerClass)}>
          {showTitle && <h2 className={styles.Title}>{sectionTitle}</h2>}
          {isTwoColumnView ? (
            <div className={styles.TwoColumnsView}>
              <Mobile>
                <ul className={classNames(styles.List, listClass)}>
                  {pageData.CompatibleImplements.map((item, index) => {
                    return (
                      <div className={styles.Tile} key={index} onClick={() => openModel(item.Product.ProductCode)}>
                        <div className={styles.ImageBox}>
                          <DynamicImage
                            imgSrc={`/api/static/media/en/products/${item.Product.ProductCode}/studio/1/n/n/640x360/i`}
                            sizes={'640x360'}
                            className={styles.Image}
                            alt={item.Product.Title}
                          />
                        </div>
                        <div className={styles.Content}>
                          <div className={styles.Info}>
                            {titleEachItemAvailable && <h3 className={styles.H3}>{item.Product.Title}</h3>}
                            {subTitleAvailable && <label className={styles.subTitle}>{item.Product.SubTitle}</label>}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </Mobile>
              <Default>
                <div className={styles.LargeView}>
                  {hasStudio && (
                    <DynamicImage
                      imgSrc={`/api/static/media/en/products/${pageData.CompatibleImplements[current].Product.ProductCode}/studio/1/n/n/640x360/i`}
                      sizes={'640x360'}
                      className={styles.Image}
                      alt={pageData.CompatibleImplements[current].Product.Title}
                    />
                  )}
                  {titleEachItemAvailable && <h3 className={styles.LTitle}>{pageData.CompatibleImplements[current].Product.Title}</h3>}
                  {subTitleAvailable && <h5 className={styles.subTitle}>{pageData.CompatibleImplements[current].Product.SubTitle}</h5>}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: autop(pageData.CompatibleImplements[current].Product.SmallDescription)
                    }}
                  />
                </div>
                <ul className={classNames(styles.List, listClass)}>
                  {pageData.CompatibleImplements.map((item, index) => {
                    return (
                      <div className={styles.Tile} key={index} onClick={() => thumbnailEventHandler(index)}>
                        <div className={styles.ImageBox}>
                          <DynamicImage
                            imgSrc={`/api/static/media/en/products/${item.Product.ProductCode}/studio/1/n/n/640x360/i`}
                            sizes={'640x360'}
                            className={styles.Image}
                            alt={item.Product.Title}
                          />
                        </div>
                        <div className={styles.Content}>
                          <div className={styles.Info}>
                            {titleEachItemAvailable && <h3 className={styles.H3}>{item.Product.Title}</h3>}
                            {subTitleAvailable && <label className={styles.subTitle}>{item.Product.SubTitle}</label>}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </Default>
            </div>
          ) : (
            <ul className={classNames(styles.List, listClass)}>
              {pageData.CompatibleImplements.map((item, index) => {
                let label;
                if (item.Tags?.some((tag) => tag.TagCode === 'Promotion' || tag.TagCode === 'Promotions')) {
                  label = item.Tags?.filter((tag) => tag.TagCode === 'Promotion' || tag.TagCode === 'Promotions').map((x) => x.TagDesc);
                }

                return (
                  <ProductTile
                    className={styles.Item}
                    key={index}
                    title={titleEachItemAvailable ? item.Product.Title : null}
                    subTitle={subTitleAvailable ? item.Product.SubTitle : null}
                    sellPrice={sellPriceEnabled ? item.SellPrice : false}
                    sellPriceText={sellPriceTextAvailable ? sellPriceText : false}
                    price={item.Product.MSRP}
                    features={item.Product.QuickFeatures}
                    productCode={item.Product.ProductCode}
                    productId={item.Product.ProductId}
                    label={label}
                    hasStudio={hasStudio}
                    compareCheckboxShow={CompareEnabled}
                    DarkMode={DarkMode}
                    featureTextEnabled={featureTextEnabled}
                    featureTitleEnabled={featureTitleEnabled}
                    imageAltEnabled={imageAltEnabled}
                    linkOutEnabled={linkOutEnabled}
                  />
                );
              })}
              {checkedProducts && compareProducts && checkedProducts.length > 0 && compareProducts.length > 0 && (
                <ComparePopup data={compareProducts} btnStatus={checkedProducts.length > 2 ? false : true} />
              )}
            </ul>
          )}
        </Container>
      )}
      <Mobile>
        <Modal
          data={modalData}
          show={showModal}
          onHide={() => setShowModal(false)}
          type="LightBox"
          solidBg="false"
          player="true"
          sourceComponent="CompatibleImplement"
          //title={modalData.FeatureText?.Title}
        />
      </Mobile>
    </section>
  );
};

export default CompatibleImplements;
