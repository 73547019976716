import { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import PageContext from '@contexts/PageContext';
import { compareProductSelector, getCompareProductsSelector } from '@stores/compareProduct/selectors';
import { resetProductCompareCodeAction } from '@stores/compareProduct/actions';
import { Container, ProductTile, ComparePopup } from '@components';
import { checkEnabled, checkValue } from '@components/OptionalFeature';
import styles from './Models.module.scss';
import { translatePrice, completeSellPrice } from '@helpers/product';

const Models = ({
  title,
  data,
  containerClass,
  listClass,
  id,
  productType,
  DarkMode,
  componentIsAvailable,
  showTitle,
  componentTitle
}) => {
  const dispatch = useDispatch();
  const pageData = useContext(PageContext);
  const router = useRouter();
  const { locale } = router;
  const [listStyle, setListStyle] = useState('');

  /*Note: change true to false for checking component Dynamically*/
  const [showOnFrench, setShowOnFrench] = useState(true);

  const checkedProducts = useSelector(compareProductSelector);

  const allSelectedProducts = useSelector(getCompareProductsSelector);

  const children = pageData.Children.filter(
    (f) => (f.VisibleDate >= new Date() && f.ExpiryDate <= new Date()) || f.Status == 1
  );

  // Array of ready products to compare
  const compareProducts = checkedProducts
    .map((x) => {
      return allSelectedProducts.find((y) => y.ProductCode == x);
    })
    .filter((f) => {
      return f ? f : false;
    });


    useEffect(() => {
      //check componnet enabled in french /english
      const DisplayValue = data?.DisplayOptions?.find((x) => x.DisplayOptionKey == 'DisplayComponent').OptionInformation;
      if (DisplayValue === true) {
        if (locale == 'fr') {
          setShowOnFrench(true);
        } else {
          setShowOnFrench(true);
        }
      } else {
        if (locale == 'fr') {
          /*Note: change true to false for checking component Dynamically*/
          setShowOnFrench(true);
        } else {
          setShowOnFrench(true);
        }
      }
    }, []);

  // check SellPriceText Enabled
  const sellPriceTextAvailable = checkEnabled(data?.DisplayOptions, 'SellPriceText');

  // sellPriceText
  const sellPriceText = checkValue(data?.DisplayOptions, 'SellPriceText', locale);

  // hasStudio
  const hasStudio = checkEnabled(data?.DisplayOptions, 'Studio');

  // has hero
  const hasbgHero = checkEnabled(data?.DisplayOptions, 'BackgroundHero');

  // Title of section
  const sectionTitle = checkValue(data?.DisplayOptions, 'ComponentHeaderTitleText', locale);

  // Compare
  const CompareEnabled = checkEnabled(data?.DisplayOptions, 'DisplayCompare');

  // featureText
  const featureTextEnabled = checkEnabled(data?.DisplayOptions, 'FeatureText');

  // featureTitle
  const featureTitleEnabled = checkEnabled(data?.DisplayOptions, 'FeatureTitle');

  // check ImageAccessibilityInfo Enabled
  const imageAltEnabled = checkEnabled(data?.DisplayOptions, 'ImageAccessibilityInfo');

  // check SellPrice is available
  const sellPriceEnable = checkEnabled(data?.DisplayOptions, 'SellPrice');

  // check SmallDescription Enabled
  const smallDescriptionEnable = checkEnabled(data?.DisplayOptions, 'SmallDescription');

  // check SubTitle Enabled
  const subTitleAvailable = checkEnabled(data?.DisplayOptions, 'SubTitle');

  // check Title of each model
  const eachModelTitleAvailable = checkEnabled(data?.DisplayOptions, 'Title');

  // check LinkOut
  const linkOutEnabled = checkEnabled(data?.DisplayOptions, 'LinkOut');

  // check label enabled
  const labelAvailable = checkEnabled(data?.DisplayOptions, 'DisplayPromotions');

  useEffect(() => {
    if (!checkedProducts.every((e) => pageData.Children.map((m) => m.ProductCode).includes(e))) {
      // dispatch(resetProductCompareCodeAction());
    }
    setListStyle(productType === 5 || productType === 11 ? styles.List : styles.CategoryList);
  }, []);

  return (
    <section className={styles.Section} id={id}>
      {(componentIsAvailable && showOnFrench) && (
        <Container className={classNames(styles.Container, containerClass)}>
          {showTitle && <h2 className={styles.Title}>{sectionTitle ? sectionTitle : title}</h2>}
          <ul className={classNames(listStyle, listClass)}>
            {children.map((item, index) => {
              let label;
              if (item.Tags?.some((tag) => tag.TagCode === 'Promotion' || tag.TagCode === 'Promotions')) {
                label = item.Tags?.filter((tag) => tag.TagCode === 'Promotion' || tag.TagCode === 'Promotions').map(
                  (x) => x.TagDesc
                );
              }
              const childrenLinkOutEnabled = checkEnabled(item?.DisplayOptions, 'LinkOut');
              const sellPrice = completeSellPrice(item.SellPrice, item.LabourRate, item.LabourHours, item.PDIHours, item.AdminFees);

              return (
                <ProductTile
                  productType={productType}
                  className={styles.Item}
                  key={index}
                  title={eachModelTitleAvailable ? item.Title : null}
                  subTitle={subTitleAvailable ? item.SubTitle : null}
                  price={item.MSRP}
                  features={item.QuickFeatures}
                  productCode={item.ProductCode}
                  productId={item.ProductId}
                  label={labelAvailable ? label : null}
                  smallDescription={smallDescriptionEnable ? item.SmallDescription : null}
                  sellPriceText={sellPriceTextAvailable ? sellPriceText : false}
                  sellPrice={sellPriceEnable ? sellPrice : false}
                  hasStudio={hasStudio}
                  compareCheckboxShow={CompareEnabled}
                  hasbgHero={hasbgHero}
                  DarkMode={DarkMode}
                  featureTextEnabled={featureTextEnabled}
                  featureTitleEnabled={featureTitleEnabled}
                  imageAltEnabled={imageAltEnabled}
                  linkOutEnabled={linkOutEnabled || childrenLinkOutEnabled}
                />
              );
            })}

            {checkedProducts && compareProducts && checkedProducts.length > 0 && compareProducts.length > 0 && (
              <ComparePopup data={compareProducts} btnStatus={checkedProducts.length > 2 ? false : true} />
            )}
          </ul>
        </Container>
      )}
    </section>
  );
};

export default Models;
