import { useRouter } from 'next/router';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { getLanguageId } from '@helpers/language';
import { Card } from '@components';

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const ArticlePreview = ({ data, showButtonText, shortDescriptionEnabled, subjectEnabled, LinkOutEnabled, imageEnabled }) => {
  const { Id, Path, PostText } = data;
  const router = useRouter();
  const { locale } = router;

  const {
    Subject: title,
    PostTextBody: body,
    ImageAccessibilityInfo: imgAlt,
    ShortDescription: subTitle,
    ButtonText: buttonText,
    UniqueTitle: uniquetitle
  } = PostText.find(({ LanguageId }) => parseInt(LanguageId) === getLanguageId(locale));

  return data ? (
    <ConditionalWrapper
      condition={LinkOutEnabled}
      wrapper={(children) => (
        <Link href={Path ? `/${Path}` : `/${uniquetitle}`} prefetch={false} key={data.Id}>
          <a>
            {children}
          </a>
        </Link>
      )}
    >
      <Card>
        {imageEnabled && (
          <Card.Media>
            <img src={`/api/static/media/byfile/posts/${Id}/post/1_N_N_N_I_640x360.jpg`}  alt={`${title}`}/>
          </Card.Media>
        )}

        <Card.Body>
          {(subjectEnabled || title) && <Card.Title>{title}</Card.Title>}
          {subTitle && <Card.SubTitle>{subTitle}</Card.SubTitle>}
          {(shortDescriptionEnabled || body) && <Card.Text dangerouslySetInnerHTML={{ __html: body }} />}

          {showButtonText && buttonText && (
            <Card.More>
              {buttonText}
              <FontAwesomeIcon icon={faChevronRight} />
            </Card.More>
          )}
        </Card.Body>
      </Card>
    </ConditionalWrapper>
  ) : null;
};

export default ArticlePreview;
