import dynamic from 'next/dynamic';
import { isFeatureEnabled, getFeatureDisplayValue } from '@components/OptionalFeature';

const HeroSection = dynamic(() => import('@containers/HeroSection'));
const ApplicationsBar = dynamic(() => import('@containers/ApplicationsBar'));
const Promotions = dynamic(() => import('@containers/Promotions'));
const Products = dynamic(() => import('@containers/Products'));
const Articles = dynamic(() => import('@containers/Articles'));
//const ProductBrowser = dynamic(() => import('@containers/ProductBrowser'));
const Description = dynamic(() => import('@containers/Description'));
const BodyContent = dynamic(() => import('@containers/BodyContent'));
const BodyFullContent = dynamic(() => import('@containers/BodyFullContent'));
const Children = dynamic(() => import('@containers/Children'));
const Warranty = dynamic(() => import('@containers/Warranty'));
const RelatedPosts = dynamic(() => import('@containers/RelatedPosts'));
const RelatedProducts = dynamic(() => import('@containers/RelatedProducts'));
const RecentlyAdded = dynamic(() => import('@containers/RecentlyAdded'));
const Categories = dynamic(() => import('@containers/Categories'));
const FeaturedChildren = dynamic(() => import('@containers/FeaturedChildren'));
const PostSummary = dynamic(() => import('@containers/PostSummary'));
const PromotionsComp = dynamic(() => import('@components/Promotions'));
const PromotionTiles = dynamic(() => import('@containers/PromotionTiles'));
const FeaturedOffers = dynamic(() => import('@containers/FeaturedOffers'));
const Success = dynamic(() => import('@containers/Success'));

const PageSectionMap = {
  'Hero': HeroSection,
  'HeroWk1': HeroSection,
  'HeroWk2': HeroSection,
  'Summary': PostSummary,
  'Applications': ApplicationsBar,
  'PromotionLeft': Promotions,
  'PromotionRight': Promotions,
  'PromotionWhole': Promotions,
  'Products': Products,
  'Related Products': RelatedProducts,
  'Articles': Articles,
  //'Product Explorer': ProductBrowser,
  'Description': Description,
  'BodyContent': BodyContent,
  'Body': BodyContent,
  'Body Full': BodyFullContent,
  'Children': Children,
  'Warranty': Warranty,
  'Related Posts': RelatedPosts,
  //'~ProductsBrowser': ProductBrowser,
  //'~Product Browser': ProductBrowser,
  'Recently Added': RecentlyAdded,
  'Categories': Categories,
  'Featured Children': FeaturedChildren,
  'Promotions': PromotionsComp,
  'PromotionTiles': PromotionTiles,
  'Featured Offers': FeaturedOffers,
  'Success': Success,
};

const PageSection = ({ data, pageId, DarkMode, isFormElements }) => {
  const { Name } = data;
  const Module = PageSectionMap[Name];

  const componentIsAvailable = isFeatureEnabled(data?.DisplayOptions, Name);

  const showTitle = isFeatureEnabled(data?.DisplayOptions, Name, 'ComponentHeaderTitleText');

  const componentTitle = getFeatureDisplayValue(data?.DisplayOptions, Name, 'ComponentHeaderTitleText', 'en');

  const props = {
    data,
    pageId,
    DarkMode,
    isFormElements,
    componentIsAvailable,
    showTitle,
    componentTitle
  };

  return Module ? <Module {...props} /> : null;
};

export default PageSection;
