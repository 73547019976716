import { useContext } from 'react';
import useSWR from 'swr';

import PageContext from '@contexts/PageContext';
import API from '@utils/api';
import { useRouter } from 'next/router';
import { ArticlePreview } from '@containers';
import { Container, Button } from '@components';
import { checkEnabled, checkValue } from '@components/OptionalFeature';
import styles from './Articles.module.scss';

const Articles = ({ data: sectionData, Title, id, componentIsAvailable }) => {
  const pageData = useContext(PageContext);
  const router = useRouter();
  const { locale } = router;



  //show buttonText
  const showButtonText = checkEnabled(sectionData?.DisplayOptions,'ButtonText');

  // buttonText
  const buttonText = checkValue(sectionData?.DisplayOptions,'ButtonText', locale);

  //check button target enabled
  const buttonTargetAvailable = checkEnabled(sectionData?.DisplayOptions,'ButtonTarget');

  //button target
  const buttonTarget = sectionData?.DisplayOptions?.find((item) => item.DisplayOptionKey === 'ButtonTarget').OptionInformation;

  // check ArticlesArrayRelatedPosts Enabled
  const ArticlesArrayRelatedPostsAvailable = checkEnabled(sectionData?.DisplayOptions,'ArticlesArrayRelatedPosts');

  // ArticlesArrayRelatedPosts
  const ArticlesArrayRelatedPosts = sectionData?.DisplayOptions?.find(
    (item) => item.DisplayOptionKey === 'ArticlesArrayRelatedPosts'
  ).OptionInformation;

  //section title
  const sectionTitleEnabled = checkEnabled(sectionData?.DisplayOptions,'ComponentHeaderTitleText');
  //The section title on the page
  const sectionTitle = checkValue(sectionData?.DisplayOptions, 'ComponentHeaderTitleText', locale);

  //buttonLink
  let buttonLink = sectionData?.DisplayOptions?.find((item) => item.DisplayOptionKey === 'ButtonLinkURL').OptionInformation;

  //check button url enabled
  let buttonLinkAvailable = checkEnabled(sectionData?.DisplayOptions, 'ButtonLinkURL');

  //check ShortDescription Enabled
  const ShortDescriptionAvailable = checkEnabled(sectionData?.DisplayOptions, 'ShortDescription');

  //check Subject Enabled
  const SubjectAvailable = checkEnabled(sectionData?.DisplayOptions,'Subject');

  //check image
  const imageEnabled = checkEnabled(sectionData?.DisplayOptions,'BackgroundImage'); 

  let posts;
  if (pageData.RelatedPosts) {
    posts = pageData.RelatedPosts;
  } else {
    if (ArticlesArrayRelatedPosts?.includes('api/')) {
      const { data: postsData } = useSWR([ArticlesArrayRelatedPosts.toLowerCase()], (url) => API.post(url));
      posts = postsData?.Children;
    }
  }

  if (buttonLink?.includes('api/')) {
    const { data: buttonLinkPostData } = useSWR([buttonLink.toLowerCase()], (url) => API.post(url));
    buttonLink = `/${buttonLinkPostData?.Path}`;
  }

  return componentIsAvailable ? (
    <section className={styles.Section} id={id}>
      <Container className={styles.Container}>
        {sectionTitleEnabled && <h2 className={styles.Title}>{sectionTitle}</h2>}
        <div className={styles.List}>
          {posts.slice(0, 4).map(({ p: post }) => (
            <ArticlePreview
              shortDescriptionEnabled={ShortDescriptionAvailable}
              subjectEnabled={SubjectAvailable}
              key={post.Id}
              data={post}
              showButtonText={showButtonText}
              LinkOutEnabled={true}
              imageEnabled={imageEnabled}
            />
          ))}
        </div>
        <Button outlined href={buttonLinkAvailable ? buttonLink : '/articles'} target={buttonTargetAvailable ? buttonTarget : null} animated>
          {showButtonText && buttonText}
        </Button>
      </Container>
    </section>
  ) : null;
};

export default Articles;
