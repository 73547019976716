import Head from 'next/head';
import { useContext } from 'react';
import classNames from 'classnames';

import PageContext from '@contexts/PageContext';
import { Layout, Container } from '@components';
import { PageSection } from '@containers';
import styles from './HomePage.module.scss';

const HomePage = () => {
  const data = useContext(PageContext);
  const { DisplayOptions, DarkMode } = data;

  return (
    <>
      <Head>
        <meta name="facebook-domain-verification" content="nyelat786vj8duni1t224v4b9p3yll" />
      </Head>
      <Layout className={styles.HomePage} DarkMode={DarkMode}>
        <Container className={classNames(styles.HomeBody)}>
          {DisplayOptions?.map((sectionData, index) => (
            <PageSection key={`homepage-section-${index}`} data={sectionData} DarkMode={DarkMode} />
          ))}
        </Container>
      </Layout>
    </>
  );
};

export default HomePage;
