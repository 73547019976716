import { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import PageContext from '@contexts/PageContext';
import { Container, ProductTile } from '@components';
import styles from './Accessories.module.scss';
import {checkEnabled} from '@components/OptionalFeature';

const Accessories = ({ data, id, DarkMode, componentIsAvailable, showTitle, componentTitle }) => {
  const pageData = useContext(PageContext);
  const { DisplayOptions } = data;
  const router = useRouter();
  const { locale } = router;

  //image alt
  //const imageAlt = DisplayOptions?.find((item)=>item.DisplayOptionKey=="ImageAccessibilityInfo").Enabled;

  //has studio
  const hasStudio = checkEnabled(DisplayOptions,'Studio');

  //has hero
  const hasbgHero = checkEnabled(DisplayOptions,'BackgroundHero');

  //Compare
  const CompareEnabled = checkEnabled(DisplayOptions, 'DisplayCompare');

  // check ImageAccessibilityInfo Enabled
  const imageAltEnabled = checkEnabled(DisplayOptions,'ImageAccessibilityInfo');

  //check LinkOut
  const linkOutEnabled = checkEnabled(DisplayOptions,'LinkOut');

  return (
    componentIsAvailable && (
      <section className={styles.Section} id={id}>
        <Container className={classNames(styles.Container)}>
          {showTitle && <h2 className={styles.Title}>{componentTitle}</h2>}
          <ul className={styles.List}>
            {pageData.RelatedProducts.map((item, index) => {
              return (
                <li className={styles.Accessories} key={index}>
                  <ProductTile
                    className={styles.Item}
                    title={item.Title}
                    subTitle={item.SubTitle}
                    productCode={item.ProductCode}
                    hasStudio={hasStudio}
                    compareCheckboxShow={CompareEnabled}
                    hasbgHero={hasbgHero}
                    productType={item.ProductType}
                    DarkMode={DarkMode}
                    accessories={true}
                    imageAltEnabled={imageAltEnabled}
                    linkOutEnabled={linkOutEnabled}
                  />
                </li>
              );
            })}
          </ul>
        </Container>
      </section>
    )
  );
};

export default Accessories;
