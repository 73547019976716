import React, { useContext } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Link from 'next/link';

import PageContext from '@contexts/PageContext';
import { Container } from '@components';
import { checkEnabled, checkValue } from '@components/OptionalFeature';
import { getLanguageId } from '@helpers/language';
import styles from './PromotionTiles.module.scss';

const DynamicImage = dynamic(() => import('@components/DynamicImage'), {
  ssr: false
});

const PromotionTiles = ({ data, componentIsAvailable, showTitle, componentTitle }) => {
  const pageData = useContext(PageContext);
  const router = useRouter();
  const { locale } = router;

   //Title of section
  const sectionTitle = checkValue(data?.DisplayOptions, 'ComponentHeaderTitleText', locale);


  //FamilyDesc enabled
  const familyDescAvailable = checkEnabled(data?.DisplayOptions, 'FamilyDesc');

  //Family descs
  const allProductsToShow = pageData.RelatedProducts?.filter(
    (item) => item.FamilyDesc !== '' && item.FamilyDesc !== null
  ).sort((a, b) => a.Sequence - b.Sequence)
  .reduce((r, product) => {
    (r['All Products'] = r['All Products'] || []).push(product);
    return r;
  }, {});

  // ImageAccessibilityInfo
  const altEnabled = checkEnabled(data?.DisplayOptions, 'ImageAccessibilityInfo');

  return (
    <section className={styles.Section}>
      {componentIsAvailable && (
        <Container className={classNames(styles.Container)}>
          {showTitle && <h2 className={styles.Title}>{sectionTitle}</h2>}
          <ul className={styles.List}>
            {Object.entries(allProductsToShow).map((item, productIndex) => (
              <React.Fragment key={productIndex}>
                {item[1].map((x, index) => (
                  <li key={index} className={styles.Tile}>
                    <Link href={`/products/${x.ProductCode}`} prefetch={false}>
                      <a>
                        <DynamicImage
                          imgSrc={`/api/static/media/byfile/products/${
                            x.ProductCode
                          }/PromotionTile/1_${locale.toUpperCase()}_N_N_I_450x325.JPG`}
                          size={'450x325'}
                          className={styles.Image}
                          alt={altEnabled ? x.Title : null}
                        />
                      </a>
                    </Link>
                  </li>
                ))}
              </React.Fragment>
            ))}
          </ul>
        </Container>
      )}
    </section>
  );
};

export default PromotionTiles;
